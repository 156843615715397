import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BrewerFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { ScaleDatasource } from './scale-datasource.service';
import { ScaleTableEntry } from './scale-table-entry.model';
import { Scale } from './scale.entity';
import { Scale as IBrewer } from './scale.model';

export class ScaleTableStore extends QueryTableStore<Scale, IBrewer, ScaleTableEntry, BrewerFilter>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: BrewerFilter)
    {
        super(paginator, sort, tableFilter, ScaleTableEntry, AppInjector.get(ScaleDatasource));
    }
}
