import { InvitationStatus, Company } from '@hemro/lib/domain';
import { HemroRoles, Profile, UserService } from '@hemro/lib/profile';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change, Info } from '@yukawa/chain-base-angular-domain';
import { PlainObject, StringKeys } from 'simplytyped';
import { Region } from '../region';
import { Store } from '../store';
import { Invitation } from './invitation.model';


export class InvitationTableEntry extends QueryTableEntry<Invitation>
{
    static viewConfig: Invitation = {
        invitationId: 0,
        invitedEmail: '',
        groupNames  : ['REG_MAN'],
        region      : {} as Region,
        store       : {} as Store,
        sendDate    : new Date() as never,
        expiryDate  : new Date() as never,
        invitedBy   : {} as Profile,
        created     : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change      : {
            date : new Date(),
            notes: '',
            user : '',
        },
        status      : InvitationStatus.NEW,
        user        : '',
        company     : {} as Company,
        remarks     : '',
    };

    public constructor(
        invitation: Invitation = InvitationTableEntry.viewConfig,
    )
    {
        super(invitation, invitation?.invitationId, invitation?.invitedEmail);

        if (invitation.sendDate) {
            invitation.sendDate = new Date(invitation.sendDate) as never;
        }

        if (invitation.expiryDate) {
            invitation.expiryDate = new Date(invitation.expiryDate) as never;
        }

        if (invitation.created) {
            invitation.created.date = new Date(invitation.created.date);
        }
        if (invitation.change) {
            invitation.change.date = new Date(invitation.change.date);
        }

        invitation.user    = invitation.invitedBy?.user?.username;
        invitation.company = invitation.invitedBy?.company;
    }

    public get viewConfig(): Invitation
    {
        return InvitationTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'INVITATION.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = Invitation>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        const options       = new Array<ISelectOption>();
        detail.entityName = 'Invitation';

        switch ((key as StringKeys<Invitation> | StringKeys<Info> | StringKeys<Store>)) {
            case 'company':
                type               = 'text';
                detail.showInTable = false;
                break;
            case 'invitationId':
                type               = 'number';
                detail.showInTable = false;
                break;
            case 'store':
                type                = 'text';
                detail.label        = 'INVITATION.INVITED_TO';
                detail.showInTable  = true;
                detail.sortable     = false;
                const region        = (item as Invitation).region;
                const store         = (item as Invitation).store;
                const company         = (item as Invitation).company;
                value               = store?.info?.name ?? region?.info?.name ?? company?.info?.name ?? '  ';
                break;
            case 'region':
                return;
            case 'invitedBy':
                type                = 'text';
                detail.showInTable  = true;
                value               = item?.['invitedBy']?.user?.username;
                break;
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group      : key,
                    showInTable: false,
                });
                return;
            case 'invitedEmail':
                type = 'text';
                break;
            case 'remarks':
                type               = 'multiline';
                detail.showInTable = false;
                break;
            case 'status':
                type = 'text';
                break;
            case 'groupNames':
                type = 'multiselect';
                detail.showInTable = false;
                break;
            case 'user':
                type               = 'text';
                detail.sortable    = false;
                detail.showInTable = false;
                break;
            case 'expiryDate':
                const hasValue     = value && !isNaN(new Date(value).getTime());
                type               = hasValue ? 'date' : 'text';
                const expired 	   = new Date().getTime() > new Date(value).getTime();
                detail.cellClass   = expired ? 'invitation-expired' : '';
                value              = hasValue ? value : ' ';
                detail.showInTable = true;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: detail.label || this.formatKey(level),
            value,
            options,
        }));
    }
}
