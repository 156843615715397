import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap
} from 'rxjs';
import { Company, CompanyService } from '@hemro/lib/admin';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { CompanyFilter } from '@hemro/lib/domain';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { isEqual } from 'lodash';

@Component({
    selector: 'app-company-search-autocomplete',
    templateUrl: './company-search-autocomplete.component.html',
    styleUrls: ['./company-search-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CompanySearchAutocompleteComponent
        }
    ]
})
export class CompanySearchAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @ViewChild('search') searchInputRef: ElementRef;
    searchControl = new FormControl('');

    onChange = (company) => {
    };
    onTouched = () => {
    };
    disabled = false;

    canLoadMore = false;
    companies: Array<Company> = [];

    readonly positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'top'
        },
    ];

    showSearchAutocomplete = false;

    get companyFilter$(): Observable<CompanyFilter> {
        return this._companyFilter.asObservable();
    }

    get loadMoreVisible(): boolean {
        return this.companies.length > 0 && this.canLoadMore;
    }

    get selectedCompany(): Company {
        return this._selectedCompany;
    }

    get displayedSelectedCompany(): string {
        if (!this._selectedCompany) {
            return null;
        }
        return `${this._selectedCompany?.info.name} (${this._selectedCompany.companyId})`;
    }

    private _selectedCompany: Company;
    private _companyFilter = new BehaviorSubject<CompanyFilter>({
        keyword: '**',
        orderBy: 'info.name',
        orderDir: 'ASC',
        pager: {
            firstResult: 0,
            pageSize: 5,
        }
    });
    private _unsubscribeAll: Subject<void> = new Subject();

    constructor(private readonly _companyService: CompanyService) {
    }

    writeValue(company: Company): void {
        this._selectedCompany = company;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.disabled ? this.searchControl.disable() : this.searchControl.enable();
    }

    async ngOnInit(): Promise<void> {
        this.searchControl.valueChanges.pipe(
            startWith(''),
            filter(value => value !== null),
            distinctUntilChanged(),
            debounceTime(300),
            tap((value: string | Company) => {
                const name = typeof value === 'string' ? value : value.info.name;
                this._companyFilter.next({
                    keyword: `*${name}*`,
                    orderBy: 'info.name',
                    orderDir: 'ASC',
                    pager: {
                        firstResult: 0,
                        pageSize: 5,
                    }
                });
            }),
            takeUntil(this._unsubscribeAll)
        ).subscribe();

        this.companyFilter$.pipe(
            distinctUntilChanged(isEqual),
            switchMap(companyFilter => this._companyService.queryCompany(companyFilter).pipe(
                    tap((companies: QueryResult<Company>) => {
                        this.canLoadMore = companies.hasMore;
                    }),
                    map((companies: QueryResult<Company>) => {
                        if (!companyFilter.pager.firstResult) {
                            this.companies = companies.items;
                        } else {
                            this.companies.push(...companies.items);
                        }
                        return companies.items;
                    })
                )),
            takeUntil(this._unsubscribeAll)
        ).subscribe();
    }

    onSelectionChange(company: Company): void {
        this.toggleAutoCompletePanel();
        this._selectedCompany = company;
        this.onChange(this._selectedCompany);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, company: Company): number {
        return company.companyId;
    }

    loadMoreCompanies(): void {
        const companyFilter = { ...this._companyFilter.value };
        this._companyFilter.next({
            ...companyFilter,
            pager: {
                ...companyFilter.pager,
                firstResult: companyFilter.pager.firstResult + companyFilter.pager.pageSize,
            }
        });
    }

    toggleAutoCompletePanel(): void {
        this.showSearchAutocomplete = !this.showSearchAutocomplete;
        if (this.showSearchAutocomplete) {
            this.setInputFocused();
        }
        this.searchControl.patchValue('');
    }

    setInputFocused(): void {
        setTimeout(() => {
            this.searchInputRef?.nativeElement?.focus();
        });
    }
}
