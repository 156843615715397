import { Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { Region } from '../region';
import { Store } from '../store';
import { Company } from '../company';
import { IScale } from './index';
import { SBrewerBinding } from './scale.model';


export class Scale extends Entity<IScale> implements IScale {
    brewerId!: string;
    storeId!: number;
    companyId!: number;
    serial!: string;
    type!: string;
    name!: string;
    created!: Required<Change>;
    change!: Required<Change>;
    bindings!: SBrewerBinding[];

    public store!: Store;
    public region!: Region;
    public company!: Company;

    public constructor(initialData: IScale) {
        super(initialData);

        if (!this.store) {
            this.store = null as never;
        }
        if (!this.region) {
            this.region = null as never;
        }
    }

    public static formatVersion(num: number): string {
        const nStr = num.toString();

        // Check size of input
        if (nStr.length < 6) {
            console.warn(`The number ${num} has less than 6 digits, unable to format.`);
            return nStr;
        }

        try {
            // Extract parts of the number
            const thirdPart = Number(nStr.slice(-4));
            const secondPart = Number(nStr.slice(-7, -4));
            const firstPart = Number(nStr.slice(0, -7));
            return `${firstPart}.${secondPart}.${thirdPart}`;
        } catch (error) {
            console.warn(`Error occurred while formatting the number ${num}: ${(error as Error).message}`);
            return nStr;
        }
    }

    //device Entity<IScale> implementation

    public get key(): string {
        return String(this.brewerId);
    }

    public override toJson(): IScale {
        return this.toJsonWithKeys(
            [
                'brewerId',
                'storeId',
                'companyId',
                'serial',
                'type',
                'name',
                'store',
                'region',
                'company'
            ],
            {},
        );
    }

    public override updateFromJson(data: IScale): void {
        this.setFromJson(data, [
            'brewerId',
            'storeId',
            'companyId',
            'serial',
            'type',
            'name',
            'created',
            'change',
            'bindings',
            'store',
            'region',
            'company',
        ], undefined, {
            created: (created: Change) => {
                if (created) {
                    created.date = new Date(created.date as never);
                }
                return created;
            },
            change: (change: Change) => {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
            store: (store: Store) => new Store(store),
            region: (region: Region) => new Region(region),
        });
    }

    //enddevice

    public override toString(): string {
        return this.brewerId;
    }
}
