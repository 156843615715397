import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileRoles, RegionRoles, StoreRoles, UserService } from '@hemro/lib/profile';

@Component({
    selector: 'app-user-nav-tab',
    templateUrl: './user-nav-tab.component.html',
    styleUrls: ['./user-nav-tab.component.scss']
})
export class UserNavTabComponent {
    @Input() links = [
        {
            label: 'PROFILE.TITLE',
            url: '/profile',
            id: 'profile'
        },
        {
            label: 'INVITATION.TITLE',
            url: '/profile/invitation',
            id: 'invitation'
        }
    ];

    get showInvitations(): boolean {
        return this._userService.hasRole(
            ...ProfileRoles.inviteUsers, StoreRoles.storeManager, RegionRoles.regionManager
        );
    }

    constructor(private _router: Router,
                private readonly _userService: UserService,
    ) {
    }

    public isActive(url: string, id: string): boolean {
        switch (id) {
            case 'profile':
                return this._router.url.includes(url) && !this._router.url.includes('invitation');
            case 'invitation':
                return this._router.url.includes(url);
            case 'grinder':
                return this._router.url.includes(url) && !this._router.url.includes('brewer');
            case 'brewer':
                return this._router.url.includes(url);
            default:
                return false;
        }

    }

    public canBeDisplayed(id: string): boolean {
        return id !== 'invitation'
            ? true
            : this.showInvitations;
    }
}
