import { DeviceStatus, DeviceStatusEntity } from '@hemro/lib/domain';
import { TranslocoService } from '@ngneat/transloco';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change } from '@yukawa/chain-base-angular-domain';
import { map } from 'rxjs';
import { PlainObject, StringKeys } from 'simplytyped';
import { Firmware, firmwareAutocompleteSearch, versionValidator } from '../firmware';
import { Region } from '../region';
import { Store, StoreService } from '../store';
import { Device } from './device.entity';
import { Device as IDevice } from './device.model';
import { HemroRoles, UserService, RegionRoles, StoreRoles } from '@hemro/lib/profile';
import { Company } from '../company';
import { DeviceDatasource } from './device-datasource.service';


export class DeviceTableEntry extends QueryTableEntry<Device, IDevice>
{
    static viewConfig: IDevice = {
        serial        : '',
        deviceId      : '',
        hmiHwProductId: '',
        status        : {
            status    : {
                bundleVersion: '',
                discHealth     : 0,
                discUsageTime  : 0,
                espSwVersion   : '',
                hmiSwVersion   : '',
                motorOnTime    : 0,
                standbyActive  : false,
                wifiConnectTime: 0,
                wifiOnTime     : 0,
            },
            deviceDate: '',
            deviceId  : '',
            cloudDate : '',
        },
        type          : '',
        subType       : '',
        name          : '',
        assemblyDate  : '',
        created       : {
            date : new Date(),
            notes: '',
            user : '',
        },
        store         : {} as Store,
        testDevice    : false,
        productionDate: '',
        companyId     : 0,
        storeId       : 0,
        bindings      : [
            {
                brewerId : '',
                brewer   : {
                    serial: '',
                },
                grinderId: '',
                device   : {
                    deviceId: '',
                },
                created  : {
                    date : new Date(),
                    notes: '',
                    user : '',
                },
            },
        ],
        bindingCode   : '',
        fixedFirmware : '',
        company       : {} as Company,
        region        : {} as Region,
        change        : {
            date : new Date(),
            notes: '',
            user : '',
        },
        distributorId : ''
    };

    public constructor(
        device: IDevice = DeviceTableEntry.viewConfig,
    )
    {
        super(
            device instanceof Device ? device : new Device(device),
            device?.deviceId,
            device?.name,
        );
    }

    public get viewConfig(): IDevice
    {
        return DeviceTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'DEVICE.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = IDevice>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
        skipDevice = false,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        const options = new Array<ISelectOption>();
        detail.entityName = 'Device';
        const canEditTestDevice = AppInjector.get(UserService).hasRole(...[
            HemroRoles.admin,
            HemroRoles.editTestDevices,
        ]);
        const canEditAllCompanies = AppInjector.get(UserService).hasRole(HemroRoles.editAllCompanies);
        const companyColumnDisplayed = AppInjector.get(DeviceDatasource).companyColumnDisplayed;
        const userService = AppInjector.get(UserService);

        switch ((key as StringKeys<IDevice> | StringKeys<DeviceStatusEntity> | StringKeys<DeviceStatus>)) {
            case 'assemblyDate':
                if (value) {
                    type  = 'date';
                    value = new Date(value);
                }
                else {
                    type  = 'text';
                    value = '';
                }
                detail.required     = true;
                detail.showInTable  = true;
                break;
            case 'bindings':
                type               = 'text';
                detail.showInTable = true;
                detail.sortable    = false;
                detail.label       = 'DEVICE.BINDINGS.BREWER.SERIAL';
                value              = item['bindings']?.[0]?.brewer?.serial || ' ';
                break;
            case 'bindingCode':
                type               = 'text';
                detail.showInTable = false;
                break;
            case 'bundleVersion':
                type            = 'version';
                detail.required = true;
                if (!value) {
                    value = ' ';
                }
                else {
                    value = /^\d+$/.test(value)
                        ? Device.formatVersion(value)
                        : value;
                }
                break;
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group      : key,
                    showInTable: false,
                });
                return;
            case 'companyId':
                type                 = 'select';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group      : key,
                    showInTable: true,
                });
                return;
            case 'deviceDate':
                type               = 'date';
                value              = new Date(value);
                detail.showInTable = false;
                break;
            case 'hmiHwProductId':
                type               = 'text';
                detail.showInTable = true;
                detail.required = true;
                break;
            case 'deviceId':
                type              = 'text';
                detail.canEdit    = !(item as Device).deviceId;
                detail.entityName = 'ngx_device';
                detail.required   = true;
                break;
            case 'fixedFirmware':
                type = 'autocomplete';
                if (value === undefined) {
                    item[key] = value = '';
                }
                detail.canEdit            = canEditTestDevice;
                detail.readonly           = !canEditTestDevice;
                detail.showInTable        = false;
                detail.validators         = [versionValidator];
                detail.autocompleteSearch = firmwareAutocompleteSearch(item as Firmware, detail);
                break;
            case 'name':
                const readonlyName = !userService.hasRole(...[
                    HemroRoles.admin,
                    HemroRoles.editDevices,
                    HemroRoles.editCompanyDevices,
                    RegionRoles.regionManager,
                    StoreRoles.storeManager
                ]);
                type                = 'text';
                detail.canEdit      = true;
                detail.readonly     = readonlyName;
                detail.required     = false;
                break;
            case 'productionDate':
                if (value) {
                    type  = 'date';
                    value = new Date(value);
                }
                else {
                    type  = 'text';
                    value = '';
                }
                detail.required = true;
                detail.showInTable = false;
                break;
            case 'region':
                type               = 'text';
                detail.showInTable = true;
                value              = item?.['store']?.region?.info?.name;
                break;
            case 'serial':
                type                    = 'text';
                detail.required         = true;
                detail.showInTable      = true;
                if (!value) {
                    value = '';
                }
                break;
            case 'subType':
                type               = 'text';
                detail.required    = true;
                detail.showInTable = true;
                break;
            case 'status':
                if (!value) {
                    value = DeviceTableEntry.viewConfig.status;
                }
                if (value.cloudDate !== undefined) {
                    detail.entityName = 'DeviceStatusEntity';
                    this.mapDetails<DeviceStatusEntity>(details, value, 'cloudDate', {
                        ...detail,
                        group   : key,
                        required: true,
                    });
                    this.mapDetails<DeviceStatusEntity>(details, value, 'status', {
                        ...detail,
                        group   : key,
                        required: true,
                    });
                }
                else {
                    detail.entityName = 'DeviceStatus';
                    this.mapDetails<DeviceStatus>(details, value, 'bundleVersion', {
                        ...detail,
                        group   : key + '.' + detail.group,
                        required: true,
                    });
                }
                return;
            case 'cloudDate':
                if (value) {
                    type  = 'date';
                    value = new Date(value);
                }
                else {
                    type  = 'text';
                    value = AppInjector.get(TranslocoService).translate('DEVICE.STATUS.CLOUD_DATE_NOT_SET');
                }
                break;
            case 'storeId':
                type                 = 'select';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'testDevice':
                type                    = 'boolean';
                detail.required         = true;
                detail.canEdit          = canEditTestDevice;
                detail.readonly         = !canEditTestDevice;
                detail.showInDetails    = canEditTestDevice;
                detail.showInTable      = canEditTestDevice;
                break;
            case 'company':
                type                 = 'text';
                value                = item?.['company']?.info?.name;
                detail.showInTable   = canEditAllCompanies && companyColumnDisplayed;
                break;
            case 'type':
                type               = 'text';
                detail.required = true;
                detail.showInTable = true;
                break;
            case 'store':
                type                = 'select';
                detail.required     = true;
                detail.canEdit      = true;
                detail.readonly     = !userService.hasRole(...[HemroRoles.admin, HemroRoles.editDevices, HemroRoles.editCompanyDevices]);
                detail.groupByField = 'store.info.name';
                detail.label        = 'DEVICE.STORE.INFO.NAME';
                detail.compareWith  = (o1: Store | number, o2: Store | number): boolean =>
                {
                    const id1 = typeof o1 === 'number' ? o1 : o1?.storeId;
                    const id2 = typeof o2 === 'number' ? o2 : o2?.storeId;

                    return id1 === id2;
                };
                detail.options$     = AppInjector.get(StoreService).queryStore({
                    orderBy   : 'info.name',
                    orderDir  : 'ASC',
                    loadRegion: true,
                }).pipe(map(queryResult =>
                    queryResult.items.map(_item => ({
                        name : `${_item.info.name} (${_item.region.info.name})`,
                        value: _item,
                    }))));
                break;
            case 'distributorId':
                type               = 'text';
                const canAccessDistributorId = userService.hasRole(...[HemroRoles.admin, HemroRoles.editDeviceDistributor]);
                detail.showInTable = canAccessDistributorId;
                detail.canEdit     = canAccessDistributorId;
                detail.readonly    = !canAccessDistributorId;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: detail.label || this.formatKey(level),
            value,
            options,
        }));
    }
}
