import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    startWith,
    Subject, switchMap,
    takeUntil,
    tap
} from 'rxjs';
import { Region, RegionService } from '@hemro/lib/admin';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { RegionFilter } from '@hemro/lib/domain';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { isEqual } from 'lodash';

@Component({
    selector: 'app-region-search-autocomplete',
    templateUrl: './region-search-autocomplete.component.html',
    styleUrls: ['./region-search-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RegionSearchAutocompleteComponent
        }
    ]
})
export class RegionSearchAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    _companyId: number;
    get companyId(): number {
        return this._companyId;
    }
    @Input() set companyId(value: number) {
        if (this._companyId !== value) {
            this._companyId = value;
            const regionFilter = { ...this._regionFilter.value };

            if (this._selectedRegion?.companyId !== value) {
                this._selectedRegion = null;
            }

            this._regionFilter.next({
                ...regionFilter,
                companyId: value,
                pager: {
                    firstResult: 0,
                    pageSize: 5,
                }
            });
        }
    }
    @Output() regionsLoaded = new EventEmitter<Region[]>();

    @ViewChild('search') searchInputRef: ElementRef;
    searchControl = new FormControl('');

    onChange = (region) => {};
    onTouched = () => {};
    disabled = false;

    canLoadMore = false;
    regions: Array<Region> = [];
    showSearchAutocomplete = false;

    readonly positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'top'
        },
    ];

    get regionFilter$(): Observable<RegionFilter> {
        return this._regionFilter.asObservable();
    }

    get loadMoreVisible(): boolean {
        return this.regions.length > 0 && this.canLoadMore;
    }

    get selectedRegion(): Region {
        return this._selectedRegion;
    }

    get clearVisible(): boolean {
        return this.regions.length > 1 && !!this.selectedRegion;
    }

    private _selectedRegion: Region;
    private _regionFilter = new BehaviorSubject<RegionFilter>({
        keyword: '**',
        orderBy: 'info.name',
        orderDir: 'ASC',
        companyId: this.companyId,
        pager: {
            firstResult: 0,
            pageSize: 5,
        }
    });
    private _unsubscribeAll: Subject<void> = new Subject();

    constructor(private readonly _regionService: RegionService) {
    }

    ngAfterViewInit(): void {
        if (this.searchInputRef) {
            setTimeout(() => {
                this.searchInputRef.nativeElement.focus();
            });
        }
    }

    writeValue(region: Region): void {
        this._selectedRegion = region;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.disabled ? this.searchControl.disable() : this.searchControl.enable();
    }

    async ngOnInit(): Promise<void> {
        this.searchControl.valueChanges.pipe(
            startWith(''),
            filter(value => value !== null),
            distinctUntilChanged(),
            debounceTime(300),
            tap((value: string | Region) => {
                const name = typeof value === 'string' ? value : value.info.name;
                this._regionFilter.next({
                    keyword: `*${name}*`,
                    companyId: this.companyId,
                    orderBy: 'info.name',
                    orderDir: 'ASC',
                    pager: {
                        firstResult: 0,
                        pageSize: 5,
                    }
                });
            }),
            takeUntil(this._unsubscribeAll)
        ).subscribe();

        this.regionFilter$.pipe(
            distinctUntilChanged(isEqual),
            switchMap(regionFilter =>
                this._regionService.queryRegion(regionFilter).pipe(
                    tap((regions: QueryResult<Region>) => {
                        this.canLoadMore = regions.hasMore;
                    }),
                    map((regions: QueryResult<Region>) => {
                        if (!regionFilter.pager.firstResult) {
                            this.regions = regions.items;
                        } else {
                            this.regions.push(...regions.items);
                        }
                        this.regionsLoaded.emit(regions.items);
                        return regions.items;
                    }),
                    tap((regions: Region[]) => {
                        if (regions?.length === 1) {
                            this._selectedRegion = regions[0];
                        }
                    })
                )),
            takeUntil(this._unsubscribeAll)
        ).subscribe();
    }

    onSelectionChange(region: Region): void {
        this.toggleAutoCompletePanel();
        this._selectedRegion = region;
        this.onChange(this._selectedRegion);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, region: Region): number {
        return region.regionId;
    }

    loadMoreRegions(): void {
        const regionFilter = { ...this._regionFilter.value };
        this._regionFilter.next({
            ...regionFilter,
            pager: {
                ...regionFilter.pager,
                firstResult: regionFilter.pager.firstResult + regionFilter.pager.pageSize,
            }
        });
    }

    toggleAutoCompletePanel(): void {
        this.showSearchAutocomplete = !this.showSearchAutocomplete;
        if (this.showSearchAutocomplete) {
            this.setInputFocused();
        }
        this.searchControl.patchValue('');
    }

    setInputFocused(): void {
        setTimeout(() => {
            this.searchInputRef?.nativeElement?.focus();
        });
    }
}
