import { CloudRecipeStatus, RecipeMode } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change } from '@yukawa/chain-base-angular-domain';
import { of } from 'rxjs';
import { PlainObject, StringKeys } from 'simplytyped';
import { TranslocoService } from '@ngneat/transloco';
import { CloudRecipe as ICloudRecipe, RecipeType } from './cloud-recipe.model';
import { CloudRecipe } from './cloud-recipe.entity';
import { DEFAULT_MODELS } from './grinder.model';

export class CloudRecipeTableEntry extends QueryTableEntry<CloudRecipe, ICloudRecipe> {
    static viewConfig: ICloudRecipe = {
        cloudRecipeId       : 0,
        model               : '',
        name                : '',
        mode                : RecipeMode.Manual,
        type                : '',
        grindTime           : 0,
        grindWeight         : 0,
        coarseness          : 0,
        brewTime            : 0,
        brewWeight          : 0,
        brewTemperature     : 0,
        brewRatio           : 0,
        bean                : '',
        change              : {
            date: new Date(),
            notes: '',
            user: '',
        },
        created             : {
            date: new Date(),
            notes: '',
            user: '',
        },
        status              : null as never,
    };

    public constructor(
        cloudRecipe: ICloudRecipe = CloudRecipeTableEntry.viewConfig,
    ) {
        super(cloudRecipe instanceof CloudRecipe ? cloudRecipe : new CloudRecipe(cloudRecipe),
            cloudRecipe?.cloudRecipeId,
            cloudRecipe?.name);

        if (cloudRecipe.created) {
            cloudRecipe.created.date = new Date(cloudRecipe.created.date);
        }
        if (cloudRecipe.change) {
            cloudRecipe.change.date = new Date(cloudRecipe.change.date);
        }
    }

    public get viewConfig(): ICloudRecipe {
        return CloudRecipeTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string {
        return 'RECIPE.';
    }

    public override init(): void {
        super.init();
    }

    protected override mapDetails<TKey = CloudRecipe>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void {
        let type: EntryDetailType;
        let value = item?.[key];
        const options = new Array<ISelectOption>();
        detail.entityName = 'Recipe';
        const readonly = item?.['status'] === CloudRecipeStatus.OBSOLETE || item?.['status'] === CloudRecipeStatus.PUBLISHED;

        switch ((key as StringKeys<CloudRecipe>)) {
            case 'model':
                type = 'select';
                detail.canEdit = true;
                detail.readonly = readonly;
                detail.required = true;
                detail.options$ = of(DEFAULT_MODELS.map(_item => ({
                    value: _item.name,
                    name: _item.name,
                })));
                value = DEFAULT_MODELS.find(_item => _item.value === value)?.name ?? ' ';
                break;
            case 'name':
                type = 'text';
                detail.readonly = readonly;
                detail.canEdit = true;
                detail.required = true;
                detail.showInTable = !!value;
                break;
            case 'bean':
                type = 'text';
                detail.readonly = readonly;
                detail.canEdit = true;
                detail.showInTable = false;
                break;
            case 'type':
                type = 'select';
                detail.showInTable = true;
                detail.readonly = readonly;
                detail.canEdit = true;
                detail.required = true;
                detail.showInTable = !!value;
                const types = Object.values(RecipeType);
                detail.options$ = of(types.map(_item => ({
                    name: _item.split(/(?=[A-Z])/).join(' '),
                    value: _item.split(/(?=[A-Z])/)?.join(' ') || '',
                })));
                value = value?.split(/(?=[A-Z])/)?.join(' ') || '';
                break;
            case 'mode':
                type = 'select';
                detail.showInTable = true;
                detail.readonly = readonly;
                detail.canEdit = true;
                detail.required = true;
                const modes = Object.values(RecipeMode);
                detail.options$ = of(modes.filter(mode => mode !== RecipeMode.Manual).map(_item => ({
                    name: AppInjector.get(TranslocoService).translate(`RECIPE.MODES.${_item.toUpperCase()}`),
                    value: _item,
                })));
                break;
            case 'grindTime':
            case 'grindWeight':
            case 'brewTime':
            case 'brewWeight':
                type = 'number';
                value = value ? Number((value/ 1000) .toFixed(4)) : ' ';
                detail.showInTable = true;
                break;
            case 'brewTemperature':
            case 'coarseness':
                type = 'number';
                value = value || ' ';
                break;
            case 'status':
                type = 'select';
                detail.showInTable = !!value;
                if (value) {
                    const phases = Object.values(CloudRecipeStatus);
                    detail.options$ = of(phases.map(_item => ({
                        name: AppInjector.get(TranslocoService).translate(`RECIPE.STATUSES.${_item}`),
                        value: _item,
                    })));
                    value = AppInjector.get(TranslocoService).translate(`RECIPE.STATUSES.${value}`);
                }
                break;
            case 'brewRatio':
                type = 'text';
                value = value ? `1:${Number(value).toFixed(1)}` : '';
                break;
            case 'change':
                this.mapDetails<Change>(details, value, 'user', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'user', {
                    ...detail,
                    group: key,
                });
                return;
            case 'cloudRecipeId':
            case 'icon':
            case 'companyId':
                detail.showInTable = false;
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key: level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
