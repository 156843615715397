import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, retry, Subscription, switchMap, tap, timer } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable()
export class NotificationPullingService {
    private _unreadCount = new BehaviorSubject<string>('');
    private _subscription: Subscription | undefined;

    constructor(private _notificationService: NotificationService) {
    }

    public get unreadCount$(): Observable<string> {
        return this._unreadCount.asObservable();
    }

    public stopInterval(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    public startInterval(intervalDurationMs?: number): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
        const intervalDuration = intervalDurationMs || 30000;
        this._subscription = timer(0, intervalDuration)
            .pipe(
                switchMap(() => this._notificationService.unreadCount().pipe(
                    catchError(() => {
                        console.log('[NotificationPullingService]:', ' Pulling notification failed.');
                        return of(0);
                    })
                )),
                tap(count => this._updateUnreadCount(count))
            )
            .subscribe();
    }

    private _updateUnreadCount(count: number): void {
        let textCount = '';
        switch (true) {
            case count === 0:
                textCount = '';
                break;
            case count > 5:
                textCount = '+5';
                break;
            default:
                textCount = count.toString(10);
                break;
        }
        this._unreadCount.next(textCount);
    }
}
